export default {
	"premier": {
		strict: 'Premier',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'premier',
	},
	"assens": {
		strict: 'Assens',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'as en',
	},
	"palezieux": {
		strict: 'Palézieux',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'pas les yeux',
	},
	"travers": {
		strict: 'Travers',
		canton: 'Neuchâtel',
		difficulty: 2,
		fallback: 'travers',
	},
	"bex": {
		strict: 'Bex',
		canton: 'Vaud',
		difficulty: 1,
		fallback: 'bée',
	},
	"riddes": {
		strict: 'Riddes',
		canton: 'Valais',
		difficulty: 1,
		fallback: 'rides',
	},
	"denges": {
		strict: 'Denges',
		canton: 'Vaud',
		difficulty: 3,
		fallback: 'd\'anges',
	},
	"semsales": {
		strict: 'Semsales',
		canton: 'Fribourg',
		difficulty: 1,
		fallback: 'sans salle',
	},
	"luins": {
		strict: 'Luins',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'loin',
	},
	"bougy": {
		strict: 'Bougy',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'bougie',
	},
	"courtemaiche": {
		strict: 'Courtemaîche',
		canton: 'Jura',
		difficulty: 3,
		fallback: 'courte mèche',
	},
	"peney": {
		strict: 'Peney',
		canton: 'Genève',
		difficulty: 3,
		fallback: 'peinait',
	},
	"sales": {
		strict: 'Sâles',
		canton: 'Fribourg',
		difficulty: 3,
		fallback: 'sale',
	},
	"lavey": {
		strict: 'Lavey',
		canton: 'Vaud',
		difficulty: 1,
		fallback: 'lavait',
	},
	"epesses": {
		strict: 'Épesses',
		canton: 'Vaud',
		difficulty: 1,
		fallback: 'épaisse',
	},
	"broc": {
		strict: 'Broc',
		canton: 'Fribourg',
		difficulty: 3,
		fallback: 'brocs',
	},
	"paudex": {
		strict: 'Paudex',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'peau des',
	},
	"rances": {
		strict: 'Rances',
		canton: 'Vaud',
		difficulty: 1,
		fallback: 'rance',
	},
	"noes": {
		strict: 'Noës',
		canton: 'Valais',
		difficulty: 3,
		fallback: 'os',
	},
	"ardon": {
		strict: 'Ardon',
		canton: 'Valais',
		difficulty: 2,
		fallback: 'ardons',
		compact: true,
	},
	"gruyere": {
		strict: 'Gruyère',
		canton: 'Fribourg',
		difficulty: 2,
		fallback: 'gruyère',
	},
	"ballens": {
		strict: 'Ballens',
		canton: 'Vaud',
		difficulty: 3,
		fallback: 'ballant',
	},
	"leytron": {
		strict: 'Leytron',
		canton: 'Valais',
		difficulty: 1,
		fallback: 'les troncs',
	},
	"fey": {
		strict: 'Fey',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'Fait',
	},
	"morat": {
		strict: 'Morat',
		canton: 'Fribourg',
		difficulty: 3,
		fallback: 'mort à',
	},
	"l'isle": {
		strict: 'L\'Isle',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'l\'île',
	},
	"buttes": {
		strict: 'Buttes',
		canton: 'Neuchâtel',
		difficulty: 1,
		fallback: 'butte',
	},
	"saint-sylvestre": {
		strict: 'Saint-Sylvestre',
		canton: 'Fribourg',
		difficulty: 3,
		fallback: 'Saint-Sylvestre',
	},
	"yverdon": {
		strict: 'Yverdon',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'hiver dont',
		compact: true,
	},
	"echallens": {
		strict: 'Échallens',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'es chalands',
		compact: true,
	},
	"le lieu": {
		strict: 'Le Lieu',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'Le lieu',
	},
	"le sentier": {
		strict: 'Le Sentier',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'le sentier',
	},
	"l'orient": {
		strict: 'L\'Orient',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'l\'orient',
	},
	"rue": {
		strict: 'Rue',
		canton: 'Fribourg',
		difficulty: 1,
		fallback: 'rue',
	},
	"yens": {
		strict: 'Yens',
		canton: 'Vaud',
		difficulty: 1,
		fallback: 'Y\'en',
	},
	"leysin": {
		strict: 'Leysin',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'les uns',
	},
	"sion": {
		strict: 'Sion',
		canton: 'Valais',
		difficulty: 1,
		fallback: 'tion',
		compact: true,
	},
	"bottens": {
		strict: 'Bottens',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'beau temps',
	},
	"crans": {
		strict: 'Crans',
		canton: 'Valais',
		difficulty: 2,
		fallback: 'cran',
	},
	"riex": {
		strict: 'Riex',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'riait',
	},
	"rivaz": {
		strict: 'Rivaz',
		canton: 'Valais',
		difficulty: 2,
		fallback: 'rriva',
		compact: true,
	},
	"gimel": {
		strict: 'Gimel',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'j\'y met le',
	},
	"faoug": {
		strict: 'Faoug',
		canton: 'Valais',
		difficulty: 2,
		fallback: 'fou',
	},
	"meyrin": {
		strict: 'Meyrin',
		canton: 'Vaud',
		difficulty: 1,
		fallback: 'mes reins',
	},
	"bassins": {
		strict: 'Bassins',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'bassin',
	},
	"iserables": {
		strict: 'Isérables',
		canton: 'Valais',
		difficulty: 2,
		fallback: 'isérables',
		compact: true,
	},
	"biere": {
		strict: 'Bière',
		canton: 'Vaud',
		difficulty: 1,
		fallback: 'bière',
	},
	"l'abbaye": {
		strict: 'L\'Abbaye',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'l\'abbaye',
	},
	"coppet": {
		strict: 'Coppet',
		canton: 'Vaud',
		difficulty: 2,
		fallback: 'copé',
		compact: true,
	},
};
