export const difficulty = {
	0: '3 caractères',
	1: '2 caractères',
	2: '1 caractère',
	3: 'Aucune',
};

export const validation = {
	false: 'Permissive',
	true: 'Stricte',
};
